/* eslint-disable */
import constantData from '@/json/data.json'
import { mapActions, mapGetters } from 'vuex'

export const securityMixin = {
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus'
    }),
    inSuperRole(role) {
      return constantData.super_roles.map(sr => sr.key).includes(role)
    },
    determineAccessibility(ctl, profile = false) {
      let camp_match = false
      let country_match = false
      let region_match = false
      let state_match = false
      let super_match = this.userLogin.croles.filter(sf => this.inSuperRole(sf.rle_key) && this.iCanSee(ctl)).length > 0
      if (!super_match) {
        this.controlPermissions
          .filter(cpf => cpf.vcxr_ctl_key === ctl)
          .map(rl => {
            let role_find = this.userLogin.croles.find(cf => cf.rle_key === rl.vcxr_rle_key)
            if (!!role_find) {
              if (!camp_match) {
                if (profile) {
                  camp_match =
                    rl.vogs_org_type === 'Camp' &&
                    !!this.individualProfile &&
                    !!this.individualProfile.camp_number &&
                    this.individualProfile.camp_number === this.userLogin.campnumber
                } else {
                  camp_match =
                    rl.vogs_org_type === 'Camp' &&
                    (!!this.officerToolbarSelected.camp_name
                      ? this.officerToolbarSelected.camp_name === this.userLogin.campname
                      : this.officerToolbarSelected.camp_number === this.userLogin.campnumber)
                }
              }
              if (!state_match) {
                if (profile) {
                  state_match =
                    rl.vogs_org_type === 'State' &&
                    !!this.individualProfile &&
                    !!this.individualProfile.camp_number &&
                    this.individualProfile.camp_number.substr(0, 3) === this.userLogin.campnumber.substr(0, 3)
                } else {
                  state_match =
                    rl.vogs_org_type === 'State' &&
                    this.officerToolbarSelected.camp_number.substr(0, 3) === this.userLogin.campnumber.substr(0, 3)
                }
              }
              if (!country_match) {
                if (profile) {
                  country_match =
                    rl.vogs_org_type === 'Country' &&
                    ['icc', 'ioc'].includes(this.userLogin.membertype.toLowerCase()) &&
                    !!this.individualProfile &&
                    !!this.individualProfile.camp_number &&
                    this.individualProfile.camp_number.substr(0, 3) === this.userLogin.campnumber.substr(0, 3)
                } else {
                  country_match =
                    rl.vogs_org_type === 'Country' &&
                    ['icc', 'ioc'].includes(this.userLogin.membertype.toLowerCase())  &&
                    this.officerToolbarSelected.country_state === this.userLogin.statekey
                }
              }
              if (!region_match) {
                region_match =
                  rl.vcxr_rle_key === constantData.region_1_key || rl.vcxr_rle_key === constantData.region_2_key
              }
            }
          })
        return state_match === true || country_match === true || camp_match === true || region_match === true
      } else {
        // super roles exceptions were made in the API
        return true
      } // clear out state.control_permissions
    },
    // this is a special case for conversations that augments the ICanSee() method
    conversationsICanSee(ctrl) {
      let foundInInstructorsAndEus = this.instructors_and_eus.findIndex(ieu => ieu.ind_key === this.demograph.ind_key) > -1
      if (foundInInstructorsAndEus) {
        return true
      }
      if (!ctrl) {
        return false
      }
      this.iCanSee(ctrl)
      return this.iCanSee(ctrl)
    }
  },
  computed: {
    ...mapGetters({
      controlPermissions: 'user/controlPermissions',
      instructors_and_eus: 'conversations/instructors_and_eus',
      demograph: 'user/demograph',
      iCanSee: 'user/iCanSee',
      individualProfile: 'membership/individualProfileHeader',
      officerToolbarSelected: 'user/officerToolbarSelected',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey',
      userLanguageKey: 'user/userLanguageKey'
    }),
  }
}

export default securityMixin
